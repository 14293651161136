<template>
  <div class="ApplicationLibraryProjectEditForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="应用信息">
          <el-form-item label="应用名称"
          :rules="[{ required: true, message: '请输入应用名称', trigger: 'change' },
          { validator: validateAppName, trigger: 'blur' }]"
          prop="customName">
            <v-input v-model="form.customName" placeholder="请填写最多5个汉字或10个英文/数字" :maxlength="10" :width="300" />
          </el-form-item>
          <el-form-item label="应用图标" :rules="[{ required: true, message: '请上传应用图标', trigger: 'change' }]" prop="subImg">
            <v-uploader :action="uploadURL" :imgUrls.sync="form.subImg">
              <template #tip>
                <span>注：图片要求：宽132px高132px，图片小于50K</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item v-if="subStatusVisible" label="应用状态" :rules="[{ required: true, message: '请选择应用状态', trigger: 'change' }]" prop="subStatus">
            <v-select v-model="form.subStatus" :options="subStatusOps" :width="width" />
          </el-form-item>
          <el-form-item label="所属版块" :rules="[{ type: 'array', required: true, message: '请选择所属版块', trigger: 'change' }]" prop="section">
            <v-cascader :value.sync="form.section" :options="sectionOps" :props="props" @change="sectionChange"/>
          </el-form-item>
          <el-form-item v-if="subTopicTypeVisible" label="话题类型" :rules="[{ required: true, message: '请选择话题类型', trigger: 'change' }]" prop="subTopicType">
            <v-select v-model="form.subTopicType" :options="subTopicTypeOps" :width="width" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { uploadURL } from 'common/api'
import { vUploader, vCascader } from 'components/control'
import { Col2Detail, Col2Block } from 'components/bussiness'
import { editGrantedURL, queryGrantedURL } from './api'
import { setSubTopicTypeOps, setSubStatusOps } from '../map'
import { getSectionOps } from '../common'

export default {
  name: 'ApplicationLibraryProjectEditForm',
  components: {
    vUploader,
    vCascader,
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      width: 200,
      communityId: undefined,
      uploadURL: `${uploadURL}?module=icon`,
      subTopicTypeOps: setSubTopicTypeOps(),
      subStatusOps: setSubStatusOps(),
      sectionOps: [],
      subStatusVisible: false,
      subTopicTypeVisible: false,
      props: {
        children: 'options'
      },
      form: {
        id: '',
        customName: '',
        subImg: '',
        subStatus: undefined,
        section: [],
        subTopicType: undefined
      },
      submitConfig: {
        queryUrl: '',
        submitUrl: editGrantedURL,
        submitMethod: 'PUT'
      }
    }
  },
  created () {
    this.$setBreadcrumb('编辑项目授权应用')
  },
  mounted () {
    const { subId, communityId } = this.$route.query
    this.communityId = communityId
    this.submitConfig.queryUrl = `${queryGrantedURL}/${subId}`
    this.$refs.formPanel.getData()
  },
  methods: {
    // APP2-1306 应用名称支持10位字符APP2-1332
    validateAppName (rule, value, callback) {
      if (value && value.length > 0) {
        let regExp = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/
        if (!regExp.test(value)) {
          callback(new Error('请填写最多5个汉字或10个英文/数字'))
        } else {
          let len = this.getByteLen(value)
          if (len > 10) {
            callback(new Error('请填写最多5个汉字或10个英文/数字'))
          } else {
            callback()
          }
        }
      } else {
        callback(new Error('请输入应用名称'))
      }
    },

    getByteLen (val) {
      var len = 0
      for (var i = 0; i < val.length; i++) {
        var a = val.charAt(i)
        // eslint-disable-next-line no-control-regex
        if (a.match(/^[\u4e00-\u9fa5]/ig) != null) {
          len += 2
        } else {
          len += 1
        }
      }
      return len
    },

    sectionChange (section) {
      if (section && section.length && section[1].sectionType === 1) {
        this.subTopicTypeVisible = true
      } else {
        this.subTopicTypeVisible = false
      }
    },
    async update (data) {
      if (data['subHandle'] === 1) {
        this.subStatusVisible = true
      }
      Object.keys(this.form).forEach(key => {
        if (!['section'].includes(key)) {
          this.form[key] = data[key]
        }
      })
      this.sectionOps = await getSectionOps({
        params: {
          communityId: this.communityId
        }
      }, true)
      const firstLevel = data['parentSectionId']
      const secondLevel = data['sectionId']
      if (firstLevel && secondLevel) {
        let firstLevelIndex = this.sectionOps.findIndex((sectionOp) => {
          return sectionOp.value === firstLevel
        })
        if (firstLevelIndex === -1) {
          this.sectionOps.unshift({
            label: data['parentSectionName'],
            value: firstLevel,
            options: []
          })
          firstLevelIndex = 0
        }
        let secondLevelIndex = this.sectionOps[firstLevelIndex].options.findIndex((sectionOp) => {
          return sectionOp.value === secondLevel
        })
        if (secondLevelIndex === -1) {
          this.sectionOps[firstLevelIndex].options.unshift({
            label: data['sectionName'],
            value: secondLevel
          })
        }
        this.form['section'] = [firstLevel, secondLevel]
      }
    },
    submitBefore (data) {
      const section = data['section']
      if (section && section.length) {
        data['sectionId'] = section[1]
      }
      delete data['section']
      if (!this.subTopicTypeVisible) {
        data['subTopicType'] = undefined
      }

      let dataObject = `${data.customName}`
      data.dataObject = dataObject
      
      return true
    }
  }
}
</script>
